<template>
    <div :class="length == 1 ? 'importantNews oneNews':'importantNews'">
        <ul>
            <li v-for="(item,index) in tabList" style="cursor: pointer" :class="{'active':index == tabIndex.tabIndex}" @click="changeTabs(index)" :key="index">{{item}}</li>
        </ul>

        <div v-if="newDataList.length != 0">
            <div v-for="(item,index) in newDataList" :key="index" >
                <p class="title" :title="item.title" @click="open(item.url)">
                    <a :href="item.url">{{item.title}}</a>
                </p>
                <div>
                    <p class="name">
                        {{item.mediaName}}
                    </p>
                    <p class="time">
                        {{item.date}}
                    </p>
                </div>
                <div class="content">
                    {{item.summary}}
                </div>
            </div>
        </div>
        <div v-if="newDataList.length == 0" style="
             height: 92%;
             text-align: center;
             padding-top: 130px;
             box-sizing: border-box">暂无数据</div>
        <div v-if="false">
            <div class="block">
                <span>共{{total}}条</span>
                <el-pagination
                        :pager-count="5"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage3"
                        :page-size="10"
                        layout="prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        name: "importantNews",
        props:{
            dataList:Array,
            tabIndex:Object,
            length:Number
        },
        data() {
            return {
                total:0,
                newDataList:[],
                tabList:['正面','中性','负面'],
                // tabIndex:0
                currentPage3: 1
            };
        },
        methods: {
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                // console.log(this.dataList)
                console.log(this.dataList[this.tabIndex.tabIndex].monitorData.slice((val-1)*10,val*10))
                var dataList = []
                console.log('this.dataList'+this.dataList)
                dataList = [...this.dataList[this.tabIndex.tabIndex].monitorData]
                this.newDataList = dataList.slice((val-1)*10,val*10)

            },
            handleClick(tab, event) {
                console.log(tab, event);
            },
            changeTabs(index){
                this.currentPage3 = 1
                this.tabIndex.tabIndex = index
                //分页
                // this.newDataList=this.dataList[index].monitorData.slice((this.currentPage3-1)*10,this.currentPage3*10)
                this.newDataList=this.dataList[index].monitorData
                this.total = this.dataList[this.tabIndex.tabIndex].monitorData.length
            }
        },
        watch: {
            dataList(newValue,oldValue){
            if(newValue.length != 0){
                this.currentPage3 = 1
                this.total = newValue[this.tabIndex.tabIndex].monitorData.length
                this.newDataList = []
                // this.newDataList = newValue[this.tabIndex.tabIndex].monitorData.slice((this.currentPage3-1)*10,this.currentPage3*10)
                this.newDataList = newValue[this.tabIndex.tabIndex].monitorData
            }
        }

        },
        created() {
            // if(this.length == 1){
            //     this.currentPage3 = 1
            //     this.newDataList = this.dataList[this.tabIndex.tabIndex].monitorData.slice((this.currentPage3-1)*10,this.currentPage3*10)
            //     this.total = this.dataList[this.tabIndex.tabIndex].monitorData.length
            //     console.log(this.total)
            // }else{
                this.newDataList = this.dataList[this.tabIndex.tabIndex].monitorData

            // }

            // this.total = 100
        }
    }
</script>

<style scoped lang="less">
    .importantNews{
        padding-bottom: 8px;
        width: 100%;
        height: 187px;
        background-color: white;
        >ul{
            display: flex;
            width: 100%;
            justify-content: space-around;
            border-bottom: 1px solid #999;
            >li{
                box-sizing: border-box;
                width: 33%;
                text-align: center;
                line-height: 40px;
                height: 40px;
                border-bottom: 2px solid transparent;
            }
            >.active{
                color: #0086F9;
                border-color: #0086F9;
            }
        }
        >div{
            box-sizing: border-box;
            height: 140px;
            overflow-y: auto;
            padding: 2px 8px;
            >div{
                padding: 14px 24px;
                margin-top: 8px;
                background-color: #F7F7F7;
                /*background-color: red;*/
                >.title{
                    font-weight: 600;
                    font-size: 16px;
                    display:-webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    >a{
                        font-weight: 600;
                        font-size: 16px;
                        color: #333;
                        text-decoration:none;
                    }
                    /*>a{*/
                    /*    font-size: 16px;*/
                    /*    color: #333;*/
                    /*    text-decoration:none;*/
                    /*}*/
                    >a:visited{
                        color: #333;
                    }
                }
                >div{
                    padding: 6px 0;
                    display: flex;
                    font-size: 12px;
                    >.name{
                        flex-grow: 1;
                    }
                    >.time{
                        text-align: right;
                        width: 150px;
                        padding-right: 6px;
                    }
                }
                >.content{
                    font-size: 14px;
                }
            }
        }
    }
    .oneNews{
        padding-bottom: 0;
        /*height: 84.5vh;*/
        height: 90.5vh;
        >div:nth-child(2){
            height: 92%;
        }
        >div:nth-child(3){
            overflow:hidden;

            height: 66px;

            .block{
                color: #606266;
                line-height: 29px;
                display: flex;
                background-color: white;
                max-width: 600px;
                float: right;
                padding: 0;
                padding-right: 4px;
                >.el-pagination{

                    max-width: 600px;
                    padding: 0;
                    >button{
                        padding: 0;
                    }
                    >.el-pager{
                        >li{
                            max-width: 20px!important;
                        }
                    }
                }
            }
        }
    }
</style>
<style>
    .importantNews .number{
        min-width: 5vw

    }
    .importantNews .el-pagination__jump{
        margin: 0;
        background-color: #ffffff;
    }
    .importantNews .el-pagination button{
        padding: 0;
    }
    .importantNews .el-pagination__editor.is-in-pagination{
        width: 30px!important;
    }
    .importantNews .el-pager>li{
        padding: 0;
        min-width: 17px
    }
</style>
