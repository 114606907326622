<template>
    <div>
        <div ref="bar" class="bar"></div>
<!--        <dataChange ref="dataChangeDialog" :visible.sync="showDataChangeDialog"></dataChange>-->

    </div>
</template>

<script>
    // import dataChange from "../dataChange";
    import * as echarts from 'echarts';
    export default {
        components:{
            // dataChange
        },
        props:{
            dataList:Array,
            title:String,

        },
        name: "bar",
        data(){
            return{
                bar:[],
                showDataChangeDialog:false,
                // screenWidth: '',
                // screenHeight: ''
            }
        },
        mounted(){
            // this.screenWidth = document.body.clientWidth;
            // this.screenHeight = document.body.clientHeight;
            // window.onresize = () => {
            //     this.$nextTick(() => {
            //         const bar = this.$refs.bar
            //         if(bar){
            //             echarts.init(bar).resize()
            //         }
            //     })
            // };
            // 在通过mounted调用即可
            // this.echartsInit()
            this.bar = this.dataList

            console.log('bar',this.bar)
            console.log(this.bar)
            if(this.bar.length != 0){
                // 在通过mounted调用即可
                this.echartsInit()
            }
        },

        watch:{
            dataList(newValue){
                if(newValue.length != 0){
                    this.bar = newValue
                    console.log('newValue',newValue)
                    // console.log(this.stackedColumnar[0])
                    this.echartsInit()
                }

            }
        },
        methods: {
            openDataFun(columns,data)  {
                this.showDataChangeDialog = true;
                this.$refs.dataChangeDialog.getMessage(columns,data);
            },
            //初始化echarts
            echartsInit(){
                const type = echarts.init(this.$refs.bar);
                // window.onresize = type.resize;
                let _this = this;
                var legendData = [];
                var series= [];
                var xdata = [];
                var val = this.bar
                var touchList = val
                if(touchList.length == 0) {
                    type.dispose();
                } else {
                    xdata = touchList[0].x
                    touchList.forEach((item) => {
                        legendData.push({
                            name: item.name,
                        })
                        series.push({
                            name: item.name,
                            type:'bar',
                            data: item.y,
                            barGap: 0,
                            barWidth:'30%',
                            itemStyle: {
                                normal: {
                                    //柱状图颜色
                                    color: '#3875C5'
                                }
                            }
                        })
                    })
                }
                type.setOption({
                    title:{
                        top:10,
                        show:true,
                        text:this.title,
                        x:'center',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            // fontFamily: 'Arial, Verdana, sans...',
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: '600',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        formatter: function (params) {
                            let htmlStr = ''
                            for(var i=0;i<params.length;i++){
                                var param = params[i];
                                var xName = param.axisValue;//x轴的名称
                                var seriesName = param.seriesName;//图例名称
                                var value = param.value;//y轴值
                                var color = param.color;//图例颜色
                                if(i===0){
                                    htmlStr += xName + '<br/>';//x轴的名称
                                }
                                htmlStr +='<div>';
                                //为了保证和原来的效果一样，这里自己实现了一个点的效果
                                htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
                                //圆点后面显示的文本
                                htmlStr += seriesName + '：' + value;
                                htmlStr += '</div>';
                            }
                            return htmlStr;
                        },
                    },
                    // toolbox: {
                    //     show: true,
                    //     right: '15',
                    //     top: '-5',
                    //     feature: {
                    //         // saveAsImage: {
                    //         //     iconStyle: {
                    //         //         borderColor: "#666"
                    //         //     },
                    //         //     emphasis: {
                    //         //         iconStyle: {
                    //         //             borderColor: "#666",
                    //         //             textFill: "#666"
                    //         //         },
                    //         //     }
                    //         // },
                    //         // dataView: { show: true, readOnly: false ,
                    //         //     optionToContent:  (opt) => {
                    //         //         let axisData = opt.xAxis[0].data; //坐标数据
                    //         //         let series = opt.series; //折线图数据
                    //         //         let tdHeads = '<td  style="padding: 2px 10px;background-color: #eeeeee;font-weight: 700;color: #333333">名称</td>'; //表头
                    //         //         let tdBodys = ''; //数据
                    //         //         series.forEach(function (item) {
                    //         //             //组装表头
                    //         //             tdHeads += `<td style="padding: 2px 10px;background-color: #eeeeee;font-weight: 700;color: #333333">${item.name}</td>`;
                    //         //         });
                    //         //         let table = `<table   border="1" style="width: 95%;margin-left:20px;border-collapse:collapse;font-size:14px;text-align:center" class="dataViewTable"><tbody><tr>${tdHeads} </tr>`;
                    //         //
                    //         //         for (let i = 0, l = axisData.length; i < l; i++) {
                    //         //             for (let j = 0; j < series.length; j++) {
                    //         //                 //组装表数据
                    //         //                 tdBodys += `<td><input class="${j}x" type="text" value="${series[j].data[i]}" style="border: none;text-align: center;color: #444444"></td>`;
                    //         //             }
                    //         //             table += `<tr><td style="padding: 0 10px;text-align: center"><input type="text" value="${axisData[i]}" style="border: none;text-align: center;color: #444444"> </td>${tdBodys}</tr>`;
                    //         //             tdBodys = '';
                    //         //         }
                    //         //         table += '</tbody></table>';
                    //         //         return table;
                    //         //     },
                    //         //     contentToOption: (HTMLDomElement, opt) => {
                    //         //         if(document.getElementsByClassName('dataViewTable').length>1){
                    //         //             this.$message.error('有其他未关闭的数据视图，请关闭后重试');
                    //         //         }else{
                    //         //             for(let i = 0;i < opt.series.length;i++){
                    //         //                 var name = 'dataX' + i;
                    //         //                 window[name] = []
                    //         //                 for (let j of document.getElementsByClassName(`${i}x`) ){
                    //         //                     window[name].push(j.value)
                    //         //                 }
                    //         //                 opt.series[i].data = window[name]
                    //         //             }
                    //         //             return opt;
                    //         //         }
                    //         //     },
                    //         // },
                    //
                    //         // magicType: { show: true, type: ['line', 'bar'] },
                    //         // restore: { show: true },
                    //         // myTool1: {
                    //         //     show: true,
                    //         //     title: "dataDisplay",
                    //         //     icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
                    //         //     onclick: function (){
                    //         //         // console.log(val);
                    //         //         var columns = [{
                    //         //             title: _this.isTopicDistribution == 0 || _this.isTopicDistribution == 1 ? _this.$t("time") : "渠道",
                    //         //             key: 'name',
                    //         //             minWidth: 100,
                    //         //         }];
                    //         //         var data = [];
                    //         //         val.forEach((item,index) => {
                    //         //             columns.push ({
                    //         //                 title: item.name,
                    //         //                 key: "num" + index,
                    //         //                 minWidth: 100,
                    //         //             })
                    //         //             if(index == 0) {
                    //         //                 item.x.forEach((ele) => {
                    //         //                     data.push({
                    //         //                         name: ele,
                    //         //                     })
                    //         //                 })
                    //         //             }
                    //         //             item.y.forEach((ele,ind) => {
                    //         //                 data[ind]["num" + index] = ele;
                    //         //             })
                    //         //         })
                    //         //         _this.openDataFun(columns,data);
                    //         //     }
                    //         // },
                    //
                    //     },
                    // },
                    grid: {
                        left: '60',
                        right: '10',
                        bottom: '30',
                        top: '36',
                    },
                    // legend: {
                    //     left: 'center',
                    //     data: legendData,
                    //     top: '0',
                    // },
                    xAxis:  {
                        type: 'category',
                        // boundaryGap: false,
                        data: xdata,
                        axisLine: {
                            lineStyle: {
                                color: "#666"
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: "#12d4c9",
                            },
                            interval: 0,
                        },
                        axisLabel: {
                            interval:0,
                            color: '#333',
                            rotate: '-45'
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#666"
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 5,
                        minInterval: 1
                    },
                    series: series,
                },true);
            }

        }
    }


</script>

<style scoped>
    .bar{
        width: 100%;
        height: 187px;
        background-color: white;
    }
</style>
