<template>
  <div class="todayPower">
    <div>{{title}}</div>
    <div>
      <div class="three">
        <span style="font-weight: 700;">{{allData}}</span>
        <div class="class">
          <span>正面：<b>{{frontData}}</b></span>
          <span>中性：<b>{{neuterData}}</b></span>
          <span>负面：<b>{{negativeData}}</b></span>
        </div></div>

    </div>

<!--    <div class="three"></div>-->
  </div>
</template>

<script>
  export default {
    props:{
      dataList:Array,
      title:String
    },
    data(){
      return{
        allData:0,
        frontData:0,
        neuterData:0,
        negativeData:0
      }
    },
    name: "todayPower",
    created() {
      this.dataList.map(m => {
        if(m.name == '总声量'){
          this.allData = m.count
        }else if(m.name == '正面声量'){
          this.frontData = m.count
        }else if(m.name == '中性声量'){
          this.neuterData = m.count
        }else if(m.name == '负面声量'){
          this.negativeData = m.count
        }
      })
      // console.log(this.dataOverview)
    }
  }
</script>

<style scoped lang="less">
  .todayPower {
    background-color: white;
    position: relative;
    width: 100%;
    height: 187px;
    >div:nth-child(1){
      padding: 10px;
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    >div:nth-last-child(1){
      >.three{
        padding-top: 16px;
        box-sizing: border-box;
        height: 130px;
        width: 90%;
        margin-left: 5%;
        margin-bottom: 4vh;
        text-align: center;
        font-size: 36px;

        border-bottom: 4px solid orange;
        >.class{
          box-sizing: border-box;
          padding: 0 28px;
          display: flex;
          justify-content: space-around;
          margin-top: 14px;
          font-size: 16px;
        }
      }
    }

  }

</style>
