<template>
<!--  <div v-if="type == 'pdf'">-->
<!--&lt;!&ndash;    <div class="tools" style="background: white;padding: 5px">&ndash;&gt;-->
<!--&lt;!&ndash;      <bk-button :theme="'default'" type="submit" :title="'基础按钮'" @click.stop="prePage" class="mr10"> 上一页</bk-button>&ndash;&gt;-->
<!--&lt;!&ndash;      <bk-button :theme="'default'" type="submit" :title="'基础按钮'" @click.stop="nextPage" class="mr10"> 下一页</bk-button>&ndash;&gt;-->
<!--&lt;!&ndash;      <span style="margin-left: 20px;z-index: 100;" class="page">{{pageNum}}/{{pageTotalNum}} </span>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;      <bk-button :theme="'default'" type="submit" :title="'基础按钮'" @click.stop="clock" class="mr10"> 顺时针</bk-button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;      <bk-button :theme="'default'" type="submit" :title="'基础按钮'" @click.stop="counterClock" class="mr10"> 逆时针</bk-button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;    </div>&ndash;&gt;-->
<!--&lt;!&ndash;    <pdf&ndash;&gt;-->
<!--&lt;!&ndash;         ref="pdf"&ndash;&gt;-->
<!--&lt;!&ndash;         :src="pdfUrl"&ndash;&gt;-->
<!--&lt;!&ndash;         :page="pageNum"&ndash;&gt;-->
<!--&lt;!&ndash;         :rotate="pageRotate"&ndash;&gt;-->
<!--&lt;!&ndash;         @progress="loadedRatio = $event"&ndash;&gt;-->
<!--&lt;!&ndash;         @page-loaded="pageLoaded($event)"&ndash;&gt;-->
<!--&lt;!&ndash;         @num-pages="pageTotalNum=$event"&ndash;&gt;-->
<!--&lt;!&ndash;         @error="pdfError($event)"&ndash;&gt;-->
<!--&lt;!&ndash;         @link-clicked="page = $event">&ndash;&gt;-->
<!--&lt;!&ndash;    </pdf>&ndash;&gt;-->
<!--  </div>-->
  <div
    :class="
      allDataList.length == 1
        ? 'reportInfo_JH oneSelfReportInfo_JH'
        : 'reportInfo_JH'
    "

    v-loading="!show"
    v-touch:swiperight="rightSlide"
  >
    <div class="reportInfo_header_JH" style="position: relative">
      <p class="title">{{ $route.query.reportName }}</p>
      <p class="littleTitle">
        <span>{{ $route.query.moduleName }}</span
        ><span class="time">发布日期：{{ createTime }}</span>
      </p>
    </div>
    <div
      :class="
        item.size == 0
          ? 'reportInfo_content_JH50 reportInfo_content_JH'
          : 'reportInfo_content_JH'
      "
      :id="allDataList.length == 1 && item.id == 20 ? 'allNews' : ''"
      v-for="(item, index) in allDataList"
      :key="numKey+item.id"
    >
      <lineChart
        v-if="item.id == 3"
        :title="item.name"
        :dataList="newLineList"
        class="echarts"
      ></lineChart>
      <cloudChart
        v-if="item.id == 8"
        :title="item.name"
        :dataList="item.data"
        class="echarts"
      ></cloudChart>
      <donutChart
        v-if="item.id == 9"
        :title="item.name"
        :dataList="pirData"
        class="echarts"
      ></donutChart>
      <todayPower
        v-if="item.id == 16"
        :title="item.name"
        :dataList="item.data"
        class="echarts"
      ></todayPower>
      <bar
        v-if="item.id == 17"
        :title="item.name"
        :dataList="barList"
        class="echarts"
      ></bar>
      <pieChart
        v-if="item.id == 18"
        :title="item.name"
        :dataList="emoPirData"
        class="echarts"
      ></pieChart>
      <stripChart
        v-if="item.id == 19"
        :title="item.name"
        :dataList="eachE"
        class="echarts"
      ></stripChart>
      <importantNews
        v-if="item.id == 20"
        :title="item.name"
        :dataList="item.data"
        class="echarts"
        :length="Number(allDataList.length)"
        :tabIndex="tabIndex"
        @changeDataList="getDataList"
      ></importantNews>
    </div>
  </div>

</template>

<script>
import bar from "../../../components/echarts/bar";
import donutChart from "../../../components/echarts/donutChart";
import pieChart from "../../../components/echarts/pieChart";
import lineChart from "../../../components/echarts/lineChart";
import stripChart from "../../../components/echarts/stripChart";
import todayPower from "../../../components/echarts/todayPower";
import cloudChart from "../../../components/echarts/cloudChart";
import importantNews from "../../../components/importantNews";
import {
  sendBuriedPointTotal,
  getReportTimerInfo,
  getSignature,
} from "@/api/api";
import { formatDate } from "../../../utils/date";
import * as echarts from "echarts";
export default {
  name: "reportInfo",
  components: {
    pieChart,
    bar,
    donutChart,
    lineChart,
    stripChart,
    todayPower,
    cloudChart,
    importantNews,
  },
  data() {
    return {
      pageNum: 1,
      pageTotalNum: 1,
      pageRotate: 0,
      // 加载进度
      loadedRatio: 0,
      curPageNum: 0,




      pdfUrl:'',
      type:'',
      noBack: false,
      cycleType: "",
      departmentName: "",
      createTime: "",
      emoPirData: [],
      pirData: [],
      lineList9: [],
      appid: "",
      moduleId: "",
      reportName: this.$route.query.reportName,
      show: false,
      reportTimer: "",
      moduleName: this.$route.query.moduleName,
      name: "",
      code: "",
      agentid: "",
      id: "",
      url: "",
      visitTime: "",
      eachE: [],
      lineList: [],
      barList: [],
      newLineList: [],
      allDataList: [],
      tabIndex: {
        tabIndex: 0,
      },
      screenWidth: null,  //屏幕尺寸
      numKey:0
    };
  },
  beforeDestroy() {
    // alert('摧毁')

    // 正式打开
    this.buriedPointTotal(
      this.agentid,
      this.dateFormat(new Date().valueOf() - this.visitTime.valueOf()),
      this.appid
    );
  },
  directives: {
    //滑动指令
    touch: {
      bind: function (el, binding, vnode) {
        var touchType = binding.arg; //传入的模式 press swipeRight swipeLeft swipeTop swipeDowm Tap
        var timeOutEvent = 0;
        var direction = "";
        //滑动处理
        var startX, startY;

        //返回角度
        function GetSlideAngle(dx, dy) {
          return (Math.atan2(dy, dx) * 180) / Math.PI;
        }

        //根据起点和终点返回方向 1：向上，2：向下，3：向左，4：向右,0：未滑动
        function GetSlideDirection(startX, startY, endX, endY) {
          var dy = startY - endY;
          var dx = endX - startX;
          var result = 0;

          //如果滑动距离太短
          if (Math.abs(dx) < 180 && Math.abs(dy) < 180) {
            return result;
          }

          var angle = GetSlideAngle(dx, dy);
          if (angle >= -45 && angle < 45) {
            result = "swiperight";
          } else if (angle >= 45 && angle < 135) {
            result = "swipeup";
          } else if (angle >= -135 && angle < -45) {
            result = "swipedown";
          } else if (
                  (angle >= 135 && angle <= 180) ||
                  (angle >= -180 && angle < -135)
          ) {
            result = "swipeleft";
          }
          return result;
        }

        el.addEventListener(
                "touchstart",
                function (ev) {
                  startX = ev.touches[0].pageX;
                  startY = ev.touches[0].pageY;

                  //判断长按
                  timeOutEvent = setTimeout(() => {
                    timeOutEvent = 0;
                    if (touchType === "press") {
                      binding.value();
                    }
                  }, 500);
                },
                false
        );

        el.addEventListener("touchmove", function (ev) {
          clearTimeout(timeOutEvent);
          timeOutEvent = 0;
        });

        el.addEventListener(
                "touchend",
                function (ev) {
                  var endX, endY;
                  endX = ev.changedTouches[0].pageX;
                  endY = ev.changedTouches[0].pageY;
                  direction = GetSlideDirection(startX, startY, endX, endY);

                  clearTimeout(timeOutEvent);

                  switch (direction) {
                    case 0:
                      break;
                    case "swipeup":
                      if (touchType === "swipeup") {
                        binding.value();
                      }
                      break;
                    case "swipedown":
                      if (touchType === "swipedown") {
                        binding.value();
                      }
                      break;
                    case "swipeleft":
                      if (touchType === "swipeleft") {
                        binding.value();
                      }
                      break;
                    case "swiperight":
                      if (touchType === "swiperight") {
                        binding.value();
                      }
                      break;
                    default:
                  }
                },
                false
        );
      },
    },
  },
  mounted () {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {   //屏幕尺寸变化就重新赋值
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },

  watch: {
    //  监听屏幕大小变化
    screenWidth(val,oldVal){
      this.numKey++
    },
  },
  methods: {
    // 上一页函数，
    prePage() {
      var page = this.pageNum
      page = page > 1 ? page - 1 : this.pageTotalNum
      this.pageNum = page
    },
    // 下一页函数
    nextPage() {
      var page = this.pageNum
      page = page < this.pageTotalNum ? page + 1 : 1
      this.pageNum = page
    },
    // 页面顺时针翻转90度。
    clock() {
      this.pageRotate += 90
    },
    // 页面逆时针翻转90度。
    counterClock() {
      this.pageRotate -= 90
    },
    // 页面加载回调函数，其中e为当前页数
    pageLoaded(e) {
      this.curPageNum = e
    },
    // 其他的一些回调函数。
    pdfError(error) {
      console.error(error)
    },




    rightSlide() {
      var u = navigator.userAgent;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (JSON.parse(sessionStorage.getItem("from")) == "/") {
        window.wx.closeWindow();
      }
    },
    //获取签名注入config
    async getSignature(agentid, appid) {
      let url = window.location.href.split("#")[0];
      try {
        let data = {
          url: url,
          agentId: agentid,
        };
        let res = await getSignature(data);
        let result = JSON.parse(res);
        wx.agentConfig({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          corpid: appid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: Number(result.timestamp), // 必填，生成签名的时间戳
          nonceStr: String(result.nonce_str), // 必填，生成签名的随机串
          signature: String(result.signature), // 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: [
            "onHistoryBack",
            "closeWindow",
            "onMenuShareAppMessage",
                  "previewFile"
          ], //必填，传入需要使用的接口名称
          success: function (res) {
            window.wx.onHistoryBack(function () {
              if (JSON.parse(sessionStorage.getItem("from")) == "/") {
                window.wx.closeWindow();
              }
              return true;
            });
            // window.wx.previewFile({
            //   url: './家化日报_2022_3_22_8_35_3.doc', // 需要预览文件的地址(必填，可以使用相对路径)
            //   name: '家化日报_2022_3_22_8_35_3.doc', // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
            //   size: 364785 // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
            // });
            // window.wx.invoke(
            //         "previewFile",
            //         {
            //             url: './家化日报_2022_3_22_8_35_3.doc', // 需要预览文件的地址(必填，可以使用相对路径)
            //             name: '家化日报_2022_3_22_8_35_3.doc', // 需要预览文件的文件名，必须有带文件格式的后缀，例如.doc(不填的话取url的最后部分，最后部分是个包含格式后缀的文件名)
            //             size: 364785 // 需要预览文件的字节大小(必填，而且大小必须正确，否则会打开失败)
            //         }
            // );
            window.wx.onMenuShareAppMessage({
              title: "舆情报告", // 分享标题
              desc: "随时查阅舆情报告，及时掌握关键信息。 ", // 分享描述
              link: window.location.href, // 分享链接；在微信上分享时，该链接的域名必须与企业某个应用的可信域名一致
              imgUrl:
                "http://inews5.xlmediawatch.com/reportFile/asslogjs/1047/lnewsImg.png", // 分享图标
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          },
          fail: function (res) {
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    //时间戳转天数
    dateFormat(times) {
      let s =
        Math.floor((times / 1000) % 60) < 10
          ? "0" + Math.floor((times / 1000) % 60)
          : Math.floor((times / 1000) % 60);
      let m =
        Math.floor((times / 1000 / 60) % 60) < 10
          ? "0" + Math.floor((times / 1000 / 60) % 60)
          : Math.floor((times / 1000 / 60) % 60);
      let h =
        Math.floor(times / 1000 / 60 / 60) < 10
          ? "0" + Math.floor((times / 1000 / 60 / 60) % 24)
          : Math.floor((times / 1000 / 60 / 60) % 24);
      return h + "小时" + m + "分" + s + "秒";
    },
    //埋点
    async buriedPointTotal(agentid, visitDuration, appid) {
      let data = {
        corpId: appid, //企业id
        agentId: agentid, //应用id
        userId: this.userInfo.userId, //用户id
        userName: this.userInfo.userInfo.name, //用户名称
        moduleName: decodeURIComponent(this.moduleName), //模块名称
        moduleId: this.$route.query.moduleId, //模块id
        mobile: this.userInfo.userInfo.mobile, //手机号
        reportName: decodeURIComponent(this.reportName), //报告名称
        departmentName: this.userInfo.department,
        reportId: this.$route.query.reportId, //报告id
        visitType: "", //访问页面类型
        visitUrl: this.url, //访问页面
        visitDuration: visitDuration, //访问时长
        operationName: "", //操作名称
        visitTime: this.visitTime, //访问时间
      };
      try {
        await sendBuriedPointTotal(data);
      } catch (e) {
        console.log(e);
      }
    },
    async getDataList(orgId, agentid, appid, startTime, noBack,fromList) {
      await this.getSignature(agentid, appid);
      try {
        let newTime = startTime.replace(/-/g, "/");
        console.log("newTime",newTime)
        let time = new Date(decodeURIComponent(newTime));
        let data
        // 从列表进入
        if(fromList){
          data = {
            orgId: Number(orgId),
            id: Number(this.$route.query.reportId),
          };
        }else{
          data = {
            orgId: Number(orgId),
            createTime: new Date(time).valueOf(),
            reportTimerId: this.$route.query.reportId,
          };
        }

        var result1 = await getReportTimerInfo(data);
        console.log('result1',JSON.parse(result1))
        await this.buriedPointTotal(agentid, -1, appid);
        // alert("埋点")
        if(JSON.parse(result1).fileType != 'html'){
          // this.type = 'pdf'
          // document.getElementById("inlineFrameExample").setAttribute("src", JSON.parse(result1).downloadInfo);
          // this.pdfUrl = JSON.parse(result1).downloadInfo
          console.log(JSON.parse(result1).downloadInfo)
          console.log(JSON.parse(JSON.parse(result1).downloadInfo).downloadPath)
          // alert(JSON.parse(result1).downloadInfo.downloadPath)
          window.location.replace(JSON.parse(JSON.parse(result1).downloadInfo).downloadPath)
        }else{
          var result = JSON.parse(JSON.parse(result1).downloadInfo).data;
          this.createTime = formatDate(
                  new Date(JSON.parse(result1).createTime),
                  "yyyy-MM-dd hh:mm:ss"
          );
          var orderArr = [];
          result.map((m) => {
            orderArr.push({
              order: m.order,
              id: m.id,
            });
          });
          orderArr.sort(function (a, b) {
            return a - b;
          });
          //存在的图表id
          let hasIdArr = [3, 8, 9, 16, 17, 18, 19, 20];
          var newResult = [];
          orderArr.map((m) => {
            result.map((n) => {
              if (m.order == n.order && hasIdArr.includes(n.id)) {
                newResult.push(n);
              }
            });
          });
          let setNewResult = Array.from(new Set(newResult));
          setNewResult.map((m) => {
            if (m.id == 17) {
              // m.data.map(n => {
              //   n.value = n.count
              // })
              //柱状图
              var x = [];
              var y = [];
              m.data.map((n) => {
                x.push(n.name);
                y.push(n.count);
              });
              this.barList = [];
              this.barList.push({
                name: m.name,
                x: x,
                y: y,
              });
            } else if (m.id == 9) {
              let data = [];
              m.data.map((n) => {
                data.push({
                  value: n.count,
                  name: n.name,
                });
              });
              this.pirData = data;
            } else if (m.id == 16) {
              console.log(m,"m")
              // m.data = [
              //   {
              //     "code": "",
              //     "count": 505393,
              //     "date": "",
              //     "describe": "",
              //     "mediaName": "",
              //     "monitorData": [],
              //     "name": "总声量",
              //     "rate": null,
              //     "summary": "",
              //     "title": "",
              //     "url": ""
              //   },
              //   {
              //     "code": "",
              //     "count": 277072,
              //     "date": "",
              //     "describe": "",
              //     "mediaName": "",
              //     "monitorData": [],
              //     "name": "正面声量",
              //     "rate": null,
              //     "summary": "",
              //     "title": "",
              //     "url": ""
              //   },
              //   {
              //     "code": "",
              //     "count": 200107,
              //     "date": "",
              //     "describe": "",
              //     "mediaName": "",
              //     "monitorData": [],
              //     "name": "中性声量",
              //     "rate": null,
              //     "summary": "",
              //     "title": "",
              //     "url": ""
              //   },
              //   {
              //     "code": "",
              //     "count": 28214,
              //     "date": "",
              //     "describe": "",
              //     "mediaName": "",
              //     "monitorData": [],
              //     "name": "负面声量",
              //     "rate": null,
              //     "summary": "",
              //     "title": "",
              //     "url": ""
              //   }
              // ]
            } else if (m.id == 18) {
              let data = [];
              m.data.map((n) => {
                data.push({
                  value: n.count,
                  name: n.name,
                });
              });
              this.emoPirData = data;
            } else if (m.id == 3) {
              m.data.map((n) => {
                var linex = [];
                var liney = [];
                n.monitorData.map((o) => {
                  linex.push(o.date);
                  liney.push(o.count);
                });
                this.newLineList.push({
                  name: n.name,
                  x: linex,
                  y: liney,
                });
              });
            } else if (m.id == 8) {
              m.data.map((n) => {
                n.value = n.count;
              });
            } else if (m.id == 19) {
              var sx = [];
              var sy = [];
              m.data.map((n) => {
                sx.push(n.name);
                sy.push(n.count);
              });
              this.eachE.push({
                name: m.name,
                x: sx,
                y: sy,
              });
            }
          });
          this.noBack = noBack;
          this.allDataList = setNewResult;
          console.log('this.allDataList')
          console.log(this.allDataList)
          // this.visitTime = new Date();
          this.show = true;
          this.needBack = JSON.parse(localStorage.getItem("needBack"));
        }

        // await this.buriedPointTotal(agentid, -1, appid);
      } catch (e) {
        console.log(e);
      }
    },
    // 正式
    async getLoginMsg() {
      this.visitTime = new Date();

      let agentid = JSON.parse(sessionStorage.getItem("agentid"));
      this.agentid = agentid;
      let appid = JSON.parse(sessionStorage.getItem("appid"));
      this.appid = appid;
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.userInfo = userInfo;
      let startTime = this.$route.query.startTime;
        if(startTime){
          this.startTime = startTime.replace(/-/g, "/");
        }else{
          this.startTime = startTime
        }
        let fromList = this.$route.query.fromList
      // this.startTime = startTime.replace(/-/g, "/");
      let noBack = this.$route.query.noBack;
      this.noBack = noBack;
      let modularsArr = userInfo.modulars.split(",");
      if (modularsArr.includes(this.$route.query.moduleId)) {
        await this.getDataList(
          userInfo.orgId,
          // '9542',
          agentid,
          appid,
          this.startTime,
          noBack,
          fromList
        );
      } else {
        this.$router.push({ path: "/noPermissions" });
      }
    },
    // async getLoginMsg() {
    //   let agentid = JSON.parse(sessionStorage.getItem("agentid"));
    //   this.agentid = agentid;
    //   let appid = JSON.parse(sessionStorage.getItem("appid"));
    //   this.appid = appid;
    //   let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    //   this.userInfo = userInfo;
    //   let startTime = this.$route.query.startTime;
    //   let fromList = this.$route.query.fromList
    //   console.log('this.startTime',this.startTime)
    //   if(startTime){
    //     this.startTime = startTime.replace(/-/g, "/");
    //   }else{
    //     this.startTime = startTime
    //   }
    //   let noBack = this.$route.query.noBack;
    //   this.noBack = noBack;
    //   // let modularsArr = userInfo.modulars.split(",");
    //   // if (modularsArr.includes(this.$route.query.moduleId)) {
    //     await this.getDataList(
    //       // userInfo.orgId,
    //       '9542',
    //       agentid,
    //       appid,
    //       this.startTime,
    //       noBack,
    //       fromList
    //     );
    //   // } else {
    //   //   this.$router.push({ path: "/noPermissions" });
    //   // }
    // },
  },
  async created() {
    this.url = window.location.href;
    await this.getLoginMsg();
    //  本地调试
    // this.agentid = 1000058;
    // this.appid = "wxc8c8428d0d0fb353";
    // this.userInfo = {};
    // console.log(this.userInfo)
    // this.userInfo.roleIds = 2
    // this.userInfo.orgId = 9542
    // await this.findModularStyleByOrgId()

    // await this.getDataList();
  },
};
</script>

<style scoped lang="less">
.reportInfo_JH {
  height: 97%;
  background-color: #f3f4f9;
  padding: 2vh 13px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  overflow-y: auto;
  .reportInfo_header_JH {
    color: #2b314b;
    width: 100%;
    > .title {
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }
    > .littleTitle {
      color: black;
      padding: 20px 0;
      text-align: center;
      span:nth-of-type(2) {
        margin-left: 2vw;
      }
    }
  }

  .reportInfo_content_JH {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    > .echarts {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #d7d7d8;
      box-shadow: 2px 2px 2px #d7d7d8;
    }
  }
  .reportInfo_content_JH50 {
    width: 49%;
    height: 187px;
  }
  #allNews {
    width: 100%;
    > div {
      height: 83.2vh;
    }
  }
  @media only screen and (max-width: 762px) {
    .reportInfo_content_JH50 {
      width: 100%;
    }
  }
}
.oneSelfReportInfo_JH {
  padding: 2vh 13px 0 13px;
}
</style>
