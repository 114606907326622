<template>
    <div>
        <!--    折线图-->
        <div ref="propagationTrends" class="propagationTrends">
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        props:{
            dataList:Array,
            title:String
        },
        components:{
            // dataChange
        },
        name: "lineChart",
        data(){
            return {
                dataX:[],
                propagationTrends:[],
                showDataChangeDialog:false,
                // screenWidth: '',
                // screenHeight: ''
            }
        },
        mounted() {
            // this.screenWidth = document.body.clientWidth;
            // this.screenHeight = document.body.clientHeight;
            // window.onresize = () => {
            //     this.$nextTick(() => {
            //         const node = this.$refs.propagationTrends
            //         if(node){
            //             // alert(node)
            //             echarts.init(node).resize()
            //         }
            //     })
            // };
            this.propagationTrends = this.dataList
            if(this.propagationTrends.length != 0){
                this.echartsInit()
            }


        },
        methods:{
            openDataFun(columns,data)  {
                this.showDataChangeDialog = true;
                this.$refs.dataChangeDialog.getMessage(columns,data);
            },
            echartsInit() {
                let _this = this
                let series = []
                var resX = []
                var resY = []
                var val = this.propagationTrends
                this.propagationTrends.map(m => {
                    series.push({
                        data: m.y,
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(220, 220, 220, 0.8)'
                        },
                        name: m.name,
                        type:'line',
                        symbolSize: 4,
                        smooth: 0.5,
                    })
                })
                //因为初始化echarts 的时候，需要指定的容器 id='main'

                echarts.init(this.$refs.propagationTrends).setOption({
                    title:{
                        top:10,
                        show:true,
                        text:this.title,
                        x:'center',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            // fontFamily: 'Arial, Verdana, sans...',
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: '600',
                        },
                    },
                    xAxis:  {
                        type: 'category',
                        boundaryGap:false,
                        // boundaryGap: _this.isSpreadTrend == 0 ? false : true,
                        data: this.propagationTrends[0].x,
                        axisLine: {
                            lineStyle: {
                                color: "#666"
                            }
                        },
                        axisTick: {
                            lineStyle: {
                                color: "#12d4c9",
                            },
                            // interval: 0,
                        },
                        axisLabel: {
                            // interval:0,
                            color: '#333',
                            rotate: '-45'
                        },
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            lineStyle: {
                                color: "#666"
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 5,
                        minInterval: 1
                    },
                    series: series,
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        formatter: function (params) {
                            let htmlStr = ''
                            for(var i=0;i<params.length;i++){
                                var param = params[i];
                                var xName = param.axisValue;//x轴的名称
                                var seriesName = param.seriesName;//图例名称
                                var value = param.value;//y轴值
                                var color = param.color;//图例颜色
                                if(i===0){
                                    htmlStr += xName + '<br/>';//x轴的名称
                                }
                                htmlStr +='<div>';
                                //为了保证和原来的效果一样，这里自己实现了一个点的效果
                                htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
                                //圆点后面显示的文本
                                htmlStr += seriesName + '：' + value;
                                htmlStr += '</div>';
                            }
                            return htmlStr;
                        },
                    },
                    grid: {
                        left: '60',
                        right: '30',
                        bottom: '40',
                        top: '36',
                    },
                    toolbox: {
                        show: true,
                        right: '15',
                        top: '-5',
                        feature: {

                        },
                    },
                })

            }
        },
        watch:{
            dataList(newValue,oldValue){
                // console.log(newValue)
                if(newValue.length != 0){
                    this.propagationTrends = newValue
                    this.echartsInit()
                }

            }
        },
        created() {

        }
    }
</script>

<style scoped>
    .propagationTrends{
        width: 100%;
        height: 187px;
        background-color: white;
    }

</style>

