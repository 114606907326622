<template>
    <div>
        <div ref="donutChart" class="donutChart"></div>
<!--        <dataChange ref="dataChangeDialog" :visible.sync="showDataChangeDialog"></dataChange>-->
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "pieChart",
        components:{
            // dataChange
        },
        props:{
            dataList:Array,
            title:String
        },
        data(){
            return{
                vocalEmotion:[],
                showDataChangeDialog:false
            }
        },
        mounted(){
            // 在通过mounted调用即可
            this.vocalEmotion = this.dataList
            console.log('this.vocalEmotion.length')
            console.log(this.vocalEmotion.length)
            if(this.vocalEmotion.length != 0){
                // 在通过mounted调用即可
                this.echartsInit()
            }
        },
        watch:{
            dataList(newValue,oldValue){
                if(newValue.length != 0){
                    this.vocalEmotion = newValue
                    // console.log(this.stackedColumnar[0])
                    this.echartsInit()
                }

            }
        },
        methods: {
            openDataFun(columns,data)  {
                this.showDataChangeDialog = true;
                this.$refs.dataChangeDialog.getMessage(columns,data);
            },
            //初始化echarts
            echartsInit() {
                // console.log()
                let _this = this;
                var data = this.vocalEmotion;
                // console.log(data)
                let colorList =  [
                    "#3C6FC8",
                    "#FF0000",
                ]
                var legendData = [];
                data.forEach(item => {
                    legendData.push({
                        icon: "circle",
                        name: item.name,
                    })
                    item.itemStyle = {
                        borderWidth: 2,
                        borderColor: '#fff', //背景色
                        // color: data.indexOf(item)==0 ? colorList[1] : data.indexOf(item) == 1 ? colorList[0] : colorList[2],
                    }

                })
                var val = data
                // console.log(data)
                //因为初始化echarts 的时候，需要指定的容器 id='main'
                echarts.init(this.$refs.donutChart).setOption({
                    color:colorList,
                    title:{
                        top:10,
                        show:true,
                        text:this.title,
                        x:'center',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            // fontFamily: 'Arial, Verdana, sans...',
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: '600',
                        },
                    },
                    roseType: "radius",
                    tooltip: {

                        // trigger: "item",
                        formatter: '{a} <br/>{b} : {c}篇 ({d}%)'
                    },
                    legend: {
                        orient: "vertical",
                        top: 6,
                        left: "4%",
                        data: legendData,
                    },
                    series: [{
                        // name: _this.$t("mediaTypeCommunication"),
                        name: "占比",
                        type: "pie",
                        radius: '60%',
                        center: ['55%', '60%'], //图的位置，距离左跟上的位置
                        data: data,
                        label: {
                            formatter: '{b}: {d}%'
                        },
                        roseType: false,

                        itemStyle: {
                            normal: {
                                borderWidth: 1, // 间距的宽度

                                label: {
                                    textStyle: {
                                        color:'#686868',
                                    }
                                },
                                labelLine : {
                                    lineStyle:{
                                        color:'#686868'
                                    }
                                }
                            }
                        },
                    }]
                })
            }

        },
        created() {
            console.log("pie")
            console.log(this.dataList)

            this.vocalEmotion = this.dataList
        }
    }
</script>

<style scoped>
    .donutChart{
        width: 100%;
        height: 187px;
        background-color: white;
    }
</style>
