<template>
  <div class="cloudOuter">
    <div v-if="dataList.length == 0" class="no-data cloud">
      <p style="font-weight: 700;font-size: 18px;padding-top: 10px;margin-bottom: 50px">{{this.title}}</p>
      <p>暂无数据</p>
    </div>

    <div v-else class="cloud" ref="cloud" ></div>

  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: "cloudChart",
    props:{
      dataList:Array,
      title:String
    },
    data() {
      return {
        data:[],
        // screenWidth: '',
        // screenHeight: ''
      }
    }, //图表实例
    watch:{
      dataList(newValue,oldValue){

        if(newValue.length != 0){
          this.data = []
          newValue.map(m => {
            let textSyle = {
              normal:{
                color:this.createRandomItemStyle()
              }
            }
            m.textStyle = textSyle
            // console.log(m)
            this.data.push(m)
          })
          // console.log(this.data)
          // console.log(this.stackedColumnar[0])
          this.echartsInit()
        }

      }
    },
    mounted() {
      // this.screenWidth = document.body.clientWidth;
      // this.screenHeight = document.body.clientHeight;
      // // alert(1)
      // window.onresize = () => {
      //   // alert(2)
      //   this.$nextTick(() => {
      //     // alert(3)
      //     const node = this.$refs.cloud
      //     console.log("node")
      //     console.log(node)
      //     if(node){
      //       // alert(4)
      //       echarts.init(node).resize()
      //     }
      //   })
      // };
      this.data = []
      this.data = this.dataList
      console.log('this.data',this.data)
      if(this.data.length != 0){

        var data = [...this.data]
        this.data = []
        // console.log(data)
        data.map(m => {
          let textSyle = {
            normal:{
              color:this.createRandomItemStyle()
            }
          }
          m.textStyle = textSyle
          // console.log(m)
          this.data.push(m)
        })
        this.echartsInit()
        // console.log(2)
      }
    },
    created() {
      // console.log(this.dataList)

    },
    beforeUpdate() {
    },
    methods: {
      createRandomItemStyle(){
        return 'rgb(' + [
          Math.round(Math.random()*180),
          Math.round(Math.random()*180),
          Math.round(Math.random()*360)
        ].join(',')+')'
      },
      createRandomValue(){
        return Math.round(Math.random()*1000)
      },
      echartsInit() {
        echarts.init(this.$refs.cloud).setOption({
          title:{
            top:10,
            show:true,
            text:this.title,
            x:'center',
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              // fontFamily: 'Arial, Verdana, sans...',
              fontSize: 18,
              fontStyle: 'normal',
              fontWeight: '600',
            },
          },
          tooltip: {//提示框组件
            trigger: 'item',
            // show: true,
            // borderWidth: 0,
            // backgroundColor: 'rgba(0,0,0,0.5)',
            // textStyle: {
            //     color: "#fff",
            // },
            formatter: (params) => {
              return `${params.data.name}: ${params.data.value}`
            }
          },

          series: [{
            width: '84%',
            autoSize: true,
            type: 'wordCloud',
            sizeRange: [12, 40],
            rotationRange: [-90, 90],
            rotationStep: 45,
            gridSize: 8,
            // imageShape:'http://c.hiphotos.baidu.com/zhidao/pic/item/d52a2834349b033bc91c09cf16ce36d3d539bd0b.jpg',
            drawOutOfBound: false,
            layoutAnimation: false,
            height:'70%',
            // data 数组格式, 必须有name和value属性,
            data: this.data,
            top: 40

          }]
        })
      },
      eConsole(e){
        console.log(e)
      }
    }
  }
</script>

<style scoped>

  .cloud{

    width: 100%;
    height: 187px;
    background-color: white;
  }
  .no-data{
    width: 100%;
    height: 187px;
    text-align: center;
  }
  .cloudOuter{
    height: 187px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
