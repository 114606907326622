<template>
    <div class="newStrip">
        <div ref="strip" class="strip"></div>
<!--        <dataChange ref="dataChangeDialog" :visible.sync="showDataChangeDialog"></dataChange>-->

    </div>
</template>

<script>
    // import dataChange from "../dataChange";
    import * as echarts from 'echarts';
    export default {
        components:{
            // dataChange
        },
        props: {
            newMediaTypeList: Array,
            dataList: Array,
            title:String,
        },
        name: "stripChart",
        data(){
            return{
                strip:[],
                isSpreadInteraction:'',
                activeMedia:'weibo',
                showDataChangeDialog:false,
                // screenWidth: '',
                // screenHeight: ''
            }
        },
        mounted(){
            // this.screenWidth = document.body.clientWidth;
            // this.screenHeight = document.body.clientHeight;
            // window.onresize = () => {
            //     this.$nextTick(() => {
            //         const node = this.$refs.strip
            //         if(node){
            //             echarts.init(node).resize()
            //         }
            //     })
            // };
            // 在通过mounted调用即可
            // this.echartsInit()
            this.strip = this.dataList
            if(this.strip.length != 0){
                // 在通过mounted调用即可
                this.echartsInit()
            }
        },
        created() {
            // console.log(this.newMediaTypeList)
            this.strip = this.dataList
            console.log('this.strip')
            console.log(this.strip)
        },

        watch:{
            dataList(newValue,oldValue){
                if(newValue.length != 0){
                    this.strip = newValue
                    // console.log(this.stackedColumnar[0])
                    this.echartsInit()
                }

            }
        },
        methods: {
            openDataFun(columns,data)  {
                this.showDataChangeDialog = true;
                this.$refs.dataChangeDialog.getMessage(columns,data);
            },
            //初始化echarts
            echartsInit(){
                var val = this.strip
                var _this = this;
                var legendData = [];
                var series= [];
                var ydata = [];
                const spreadInteraction = echarts.init(this.$refs.strip);
                spreadInteraction.clear();
                if(val.length == 0) {
                    spreadInteraction.dispose();
                } else {
                    ydata = val[0].x
                    val.forEach((item,index) => {
                        legendData.push({
                            name: item.name,
                        })
                        series.push({
                            name: item.name,
                            type:'bar',
                            data: item.y,
                            barGap: 0,
                            itemStyle: {
                                normal: {
                                    //柱状图颜色
                                    color: '#3875C5'
                                }
                            }
                        })
                    })
                }
                spreadInteraction.setOption({
                    title:{
                        top:10,
                        show:true,
                        text:this.title,
                        x:'center',
                        textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
                            // fontFamily: 'Arial, Verdana, sans...',
                            fontSize: 18,
                            fontStyle: 'normal',
                            fontWeight: '600',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        textStyle:{
                            align:'left'
                        },
                        formatter: function (params) {
                            let htmlStr = ''
                            for(var i=0;i<params.length;i++){
                                var param = params[i];
                                var xName = param.axisValue;//x轴的名称
                                var seriesName = param.seriesName;//图例名称
                                var value = param.value;//y轴值
                                var color = param.color;//图例颜色
                                if(i===0){
                                    htmlStr += xName + '<br/>';//x轴的名称
                                }
                                htmlStr +='<div>';
                                //为了保证和原来的效果一样，这里自己实现了一个点的效果
                                htmlStr += '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:'+color+';"></span>';
                                //圆点后面显示的文本
                                htmlStr += seriesName + '：' + value;
                                htmlStr += '</div>';
                            }
                            return htmlStr;
                        },
                    },
                    toolbox: {
                        show: true,
                        right: '15',
                        top: '-5',
                        feature: {
                        },
                    },
                    // legend: {
                    //     data: legendData
                    // },
                    grid: {
                        left: '3%',
                        right: '4%',
                        top: '18%',
                        bottom:'5%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'value',
                        boundaryGap: [0, 0.01]
                    },
                    yAxis: {
                        type: 'category',
                        data: ydata,
                        inverse: true,
                    },
                    series: series
                },true);
            }

        }
    }
</script>

<style scoped>
    .strip{
        width: 100%;
        height: 187px;
        background-color: white;
    }

    @media only screen and (max-width:970px){
        .top10PointerUl{
            width: 100%;
            overflow: hidden;
        }
        .top10Pointer{
            text-align: center;
            float: left;
            width: 33%;
            max-width: 150px;
        }
    }
</style>
<style lang="less">
    .newStrip .tool-box {
        overflow: hidden;
        > ul {
            box-sizing: border-box;
            float: left;
            width: calc(100% - 50px);
            li {
                height: 30px;
                padding: 0 15px;
                border: 1px solid #ddd;
                float: left;
                line-height: 28px;
                margin: -1px 0 0 -1px;
                &.active {
                    position: relative;
                    z-index: 1;
                    border: 1px solid #12d4c9;
                    color: #fff;
                    // background: #12d4c9;
                }
            }
        }
        > i {
            float: right;
            line-height: 30px;
            font-size: 20px;
            color: #666

        }
    }
</style>
